var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" container"},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"8","offset-lg":"2","offset-md":"2"}},[_c('Breadcrumbs',{attrs:{"items":[
          {
            text: 'All Members',
            disabled: false,
            href: '/admin/customers/all',
          },
          {
            text: !!_vm.$route.query.id ? 'Edit Member' : 'Add Member',
            disabled: true,
            href: !!_vm.$route.query.id
              ? '/admin/customers/add'
              : '/admin/customers/all',
          } ]}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex flex-column mb-3 ml-1 mt-n4"},[_c('PageTitle',{staticClass:" font font-weight-bold font-size-lg mb-4",attrs:{"text":!!_vm.$route.query.id ? 'Edit Staff Details' : "New Member"}})],1),_c('div',{staticClass:"d-flex flex-column mx-2 flex-grow-1"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addNewMember)}}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('ValidationProvider',{staticClass:"flex flex-row flex-grow-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md ma-1",attrs:{"label":"Last Name","outlined":"","hint":"Enter members' last name ","error-messages":errors[0]},model:{value:(_vm.member.firstName),callback:function ($$v) {_vm.$set(_vm.member, "firstName", $$v)},expression:"member.firstName"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"flex flex-row flex-grow-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md ma-1",attrs:{"label":"First Name","outlined":"","hint":"Enter members' last name ","error-messages":errors[0]},model:{value:(_vm.member.lastName),callback:function ($$v) {_vm.$set(_vm.member, "lastName", $$v)},expression:"member.lastName"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('ValidationProvider',{staticClass:"flex flex-row flex-grow-1",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md ma-1",attrs:{"label":"Email Address","outlined":"","hint":"Enter members' email address ","error-messages":errors[0]},model:{value:(_vm.member.emailAddress),callback:function ($$v) {_vm.$set(_vm.member, "emailAddress", $$v)},expression:"member.emailAddress"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"flex flex-row flex-grow-1",attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md ma-1",attrs:{"label":"Phone number","outlined":"","hint":"Enter members' phone number ","error-messages":errors[0]},model:{value:(_vm.member.phoneNumber),callback:function ($$v) {_vm.$set(_vm.member, "phoneNumber", $$v)},expression:"member.phoneNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('ValidationProvider',{staticClass:"flex flex-row flex-grow-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:" font font-sm ma-1",attrs:{"label":"Date Of Birth","readonly":"","outlined":"","error-messages":errors[0]},model:{value:(_vm.member.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.member, "dateOfBirth", $$v)},expression:"member.dateOfBirth"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"font font-md",attrs:{"active-picker":_vm.activePicker,"min":"1950-01-01","max":"2010-12-31"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.member.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.member, "dateOfBirth", $$v)},expression:"member.dateOfBirth"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"flex flex-row flex-grow-1 ma-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"font font-size-md font-weight-medium",attrs:{"label":"Marital Status","outlined":"","items":[
                        { name: 'Married', value: 'M' },
                        { name: 'No Married', value: 'N' } ],"item-value":"value","item-text":"name","error-messages":errors[0]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticClass:"font font-weight-medium font-sm font-size-md"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.member.maritalStatus),callback:function ($$v) {_vm.$set(_vm.member, "maritalStatus", $$v)},expression:"member.maritalStatus"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary","type":"submit"}},[_c('span',{staticClass:"font font-sm font-weight-medium "},[_vm._v("Save ")])])],1)])]}}])})],1)])],1)],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }