<template>
  <div class=" container">
    <v-row style="width: 100%">
      <v-col cols="8" offset-lg="2" offset-md="2" class="mt-4">
        <Breadcrumbs
          :items="[
            {
              text: 'All Members',
              disabled: false,
              href: '/admin/customers/all',
            },
            {
              text: !!$route.query.id ? 'Edit Member' : 'Add Member',
              disabled: true,
              href: !!$route.query.id
                ? '/admin/customers/add'
                : '/admin/customers/all',
            },
          ]"
        />
        <v-row>
          <v-col>
            <div class="d-flex flex-column mb-3 ml-1 mt-n4">
              <PageTitle
                :text="!!$route.query.id ? 'Edit Staff Details' : `New Member`"
                class=" font font-weight-bold font-size-lg mb-4"
              />
            </div>
            <div class="d-flex flex-column mx-2 flex-grow-1">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(addNewMember)">
                  <div
                    class="d-flex flex-row flex-grow-1 justify-space-between"
                  >
                    <ValidationProvider
                      class="flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Last Name"
                        outlined
                        v-model="member.firstName"
                        hint="Enter members' last name "
                        class="font font-weight-medium font-size-md ma-1"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      class="flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="First Name"
                        outlined
                        v-model="member.lastName"
                        hint="Enter members' last name "
                        class="font font-weight-medium font-size-md ma-1"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    class="d-flex flex-row flex-grow-1 justify-space-between"
                  >
                    <ValidationProvider
                      class="flex flex-row flex-grow-1"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Email Address"
                        outlined
                        v-model="member.emailAddress"
                        hint="Enter members' email address "
                        class="font font-weight-medium font-size-md ma-1"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      class="flex flex-row flex-grow-1"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Phone number"
                        outlined
                        v-model="member.phoneNumber"
                        hint="Enter members' phone number "
                        class="font font-weight-medium font-size-md ma-1"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    class="d-flex flex-row flex-grow-1 justify-space-between"
                  >
                    <ValidationProvider
                      class="flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="member.dateOfBirth"
                            label="Date Of Birth"
                            readonly
                            class=" font font-sm ma-1"
                            outlined
                            :error-messages="errors[0]"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="member.dateOfBirth"
                          :active-picker.sync="activePicker"
                          class="font font-md"
                          min="1950-01-01"
                          max="2010-12-31"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </ValidationProvider>
                    <ValidationProvider
                      class="flex flex-row flex-grow-1 ma-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        label="Marital Status"
                        outlined
                        class="font font-size-md font-weight-medium"
                        :items="[
                          { name: 'Married', value: 'M' },
                          { name: 'No Married', value: 'N' },
                        ]"
                        item-value="value"
                        v-model="member.maritalStatus"
                        item-text="name"
                        :error-messages="errors[0]"
                      >
                        <template #item="{item}">
                          <span
                            class="font font-weight-medium font-sm font-size-md"
                          >
                            {{ item.name }}
                          </span>
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </div>

                  <div class="d-flex flex-row flex-grow-1">
                    <v-spacer />
                    <v-btn
                      :disabled="isLoading"
                      :loading="isLoading"
                      color="primary"
                      type="submit"
                    >
                      <span class="font font-sm font-weight-medium "
                        >Save
                      </span>
                    </v-btn>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { numeric, required, email } from 'vee-validate/dist/rules';
import SnackBarMixins from '../../../../mixins/SnackBarMIxins';
import { loadComponent } from '@/utils/helpers';
import { mapGetters } from 'vuex';
extend('required', {
  ...required,
  message: `Field is required`,
});
extend('numeric', {
  ...numeric,
  message: `Field only accept only numeric characters`,
});
extend('email', {
  ...email,
  message: `Invalid email address provided`,
});
import moment from 'moment';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: loadComponent(`component/SnackBar`),
    PageTitle: loadComponent(`component/PageTitle`),
    Breadcrumbs: loadComponent(`component/Breadcrumbs`),
  },
  computed: {
    ...mapGetters({
      isLoading: 'getIsLoading',
      details: 'members/getMemberDetails',
    }),
  },
  mixins: [SnackBarMixins],
  data() {
    return {
      menu: false,
      date: null,
      activePicker: null,
      member: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        maritalStatus: '',
        dateOfBirth: null,
        phoneNumber: '',
      },
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    details(payload) {
      console.log(payload);
      const {
        firstName,
        lastName,
        phoneNo,
        emailAddress,
        maritalStatus,
        birthDate,
      } = payload;
      this.member = {
        firstName,
        lastName,
        phoneNumber: phoneNo,
        emailAddress,
        maritalStatus,
        dateOfBirth: moment(birthDate).format('YYYY-MM-DD'),
      };
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    addNewMember() {
      if (this.$route.query.id) {
        this.$store.dispatch('members/update', {
          _id: this.$route.query.id,
          ...this.member,
        });
        return;
      }
      this.$store.dispatch('members/create', {
        ...this.member,
      });
    },
  },
  created() {
    if (this.$route.query.id) {
      this.$store.dispatch('members/details', this.$route.query.id);
    }
  },
};
</script>
